// 
// user.scss
// Use this to write your custom SCSS
//

@use 'user-variables' as *;

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: .83333rem;
}

.avatar-md {
  width: 3.3rem;
  height: 3.3rem;
  font-size: 1rem;
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem;
}


// ////////////////////////////////////////////////////
// Below rules are from bootstrap _reboot.scss
// ////////////////////////////////////////////////////
//
// Links
//
a {
  color: $primary-color;
  text-decoration: none;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  &:hover {
    color: $primary-hover-color;
    text-decoration: none;
  }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use
//    the `inherit` value on things like `<th>` elements.

body {
  margin: 0;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263F;
  text-align: left;
  background-color: #F9FBFD;
}


// ////////////////////////////////////////////////////
// Below rules are from bootstrap _images.scss
// ////////////////////////////////////////////////////
.img-thumbnail {
  padding: .25rem;
  background-color: #F9FBFD;
  border: 1px solid #E3EBF6;
  border-radius: .375rem;
  max-width: 100%;
  height: auto;
}
/* 
this file is auto added to all other imported style sheets
only put variables and mixins in this file
*/

/* 
Custom variables
- `@use` adds a namespace to variables; 
- in order to continue using variables without a namespace we need to import it with `as *`
- more info: 
    - https://stackoverflow.com/questions/62127255/sass-use-makes-error-undefined-variable/63012314#63012314
    - https://sass-lang.com/documentation/at-rules/use/#choosing-a-namespace
*/
@use '../../../scss/user-variables' as *;
.pdf-preview {
  &.pdf-preview--full-width {
    canvas {
      width: 100% !important;
    }
    img {
      height: 100%;
    }
  }

  &.pdf-preview--full-height {
    .react-pdf__Page {
      height: 100%;
      canvas {
        height: 100% !important;
      }
    }
    .react-pdf__message--error {
      height: 100%;
      display: flex;
      img {
        height: 100%;
      }
    }
  }

  &.pdf-preview--auto-height {
    canvas {
      height: auto !important;
    }
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent.textLayer {
    pointer-events: none;
  }
}

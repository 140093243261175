@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('fonts/IBMPlexSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

.ip {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'IBM Plex Sans' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

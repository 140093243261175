/* 
this file is auto added to all other imported style sheets
only put variables and mixins in this file
*/

/* 
Custom variables
- `@use` adds a namespace to variables; 
- in order to continue using variables without a namespace we need to import it with `as *`
- more info: 
    - https://stackoverflow.com/questions/62127255/sass-use-makes-error-undefined-variable/63012314#63012314
    - https://sass-lang.com/documentation/at-rules/use/#choosing-a-namespace
*/
@use '../../../scss/user-variables' as *;
.status {
  color: #6e84a3;

  &__circle {
    &--sm {
      font-size: 1rem;
    }
    &--md {
      font-size: 2rem;
    }
    &--lg {
      font-size: 3rem;
    }
    &--warning {
      color: #ffc875;
    }
    &--success {
      color: #30e398;
    }
    &--default {
      color: #c5c4c3;
    }
    &--danger {
      color: #e63757;
    }
  }
  &__label {
    margin-left: 6px;
    padding-top: 2px;
    // color: #232527;
    &--active {
      // color: #12263f;
      font-weight: bold;
    }
    &--sm {
      font-size: 0.9rem;
    }
  }
}

/* 
this file is auto added to all other imported style sheets
only put variables and mixins in this file
*/

/* 
Custom variables
- `@use` adds a namespace to variables; 
- in order to continue using variables without a namespace we need to import it with `as *`
- more info: 
    - https://stackoverflow.com/questions/62127255/sass-use-makes-error-undefined-variable/63012314#63012314
    - https://sass-lang.com/documentation/at-rules/use/#choosing-a-namespace
*/
@use '../../../scss/user-variables' as *;
.pac-container {
  z-index: 99999;
  margin-top: 4px;
  &:after {
    content: none;
  }
}

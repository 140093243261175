//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// colors
$primary-color: #5F1F8A;
$primary-hover-color: #AB178B;
$highlight-color: #F0F0FF;
$primary-color-alt: #0D1A5B;

// paths
$path-to-img: '../../assets/img' !default;
$path-to-fonts: '../../fonts/' !default;

/* 
this file is auto added to all other imported style sheets
only put variables and mixins in this file
*/

/* 
Custom variables
- `@use` adds a namespace to variables; 
- in order to continue using variables without a namespace we need to import it with `as *`
- more info: 
    - https://stackoverflow.com/questions/62127255/sass-use-makes-error-undefined-variable/63012314#63012314
    - https://sass-lang.com/documentation/at-rules/use/#choosing-a-namespace
*/
@use '../../../scss/user-variables' as *;
.badge-preview {
  max-width: 400px;
  overflow: hidden;

  &__body-images {
    max-width: 120px;
  }

  &__profile-image {
    height: 120px;
    max-width: 100%;
    object-fit: cover;
  }

  &__profile-qr {
    height: 120px;
    max-width: 100%;
    object-fit: cover;
    text-align: right;
  }
}

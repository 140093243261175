/* 
this file is auto added to all other imported style sheets
only put variables and mixins in this file
*/

/* 
Custom variables
- `@use` adds a namespace to variables; 
- in order to continue using variables without a namespace we need to import it with `as *`
- more info: 
    - https://stackoverflow.com/questions/62127255/sass-use-makes-error-undefined-variable/63012314#63012314
    - https://sass-lang.com/documentation/at-rules/use/#choosing-a-namespace
*/
@use 'user-variables' as *;
@use '../fonts/ibmplexsans/ibmplexsans.scss';
@use '../fonts/whisper/whisper.scss';

@use 'helpers';

// Custom core
@use 'user';

body {
  background-color: white;
}

#app {
  font-family: 'IBM Plex Sans';
}

@media print { 
  .no-print { 
     visibility: hidden; 
  } 
}

// TODO: Remove this after refactor all sibling modals to use a single modal
// https://github.com/tailwindlabs/headlessui/issues/1199#issuecomment-1222409459
html {
  overflow: auto !important;
  position: static !important
}

.table-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);

  .loading-error {
    position: absolute;
    padding-top: 200px;
  }
}
